<script>
	import { layoutComputed } from "@/store/helpers";

	import Sidebar from "@/components/Sidebar";
	import Topbar from "@/components/Topbar";
	import Rightbar from "@/components/Rightbar";
	import Footer from "@/components/Footer";
	import { mapGetters, mapActions } from "vuex";
	/**
	 * Vertical layout
	 */
	export default {
		components: {
			Sidebar,
			Topbar,
			Rightbar,
			Footer,
		},
		data() {
			return {
				isMenuCondensed: false,
				idleTime: Number(process.env.VUE_APP_IDLE_DURATION),
			};
		},
		computed: {
			...layoutComputed,
			...mapGetters("authModule", ["isAdmin"]),
		},
		mounted() {
			document.body.removeAttribute("data-layout-mode");
			document.body.removeAttribute("data-sidebar-showuser");
			if (!this.isAdmin) {
				document.body.setAttribute("data-layout-mode", "horizontal");
			}
		},
		methods: {
			onremind(time) {
				this.$bvToast.toast(`You will be logged out in ${time} seconds.`, {
					title: "Attention - Screen Idle",
					autoHideDelay: 7000,
					solid: true,
					variant: "danger",
				});
			},
			async onIdle() {
				await this.logout();
				this.$router.push("/login");
			},
			...mapActions("layoutModule", ["changeLayoutType"]),
			...mapActions("authModule", ["logout"]),
			toggleRightSidebar() {
				document.body.classList.toggle("right-bar-enabled");
			},
			hideRightSidebar() {
				document.body.classList.remove("right-bar-enabled");
			},
			toggleMenu() {
				this.isMenuCondensed = !this.isMenuCondensed;
				if (this.isMenuCondensed)
					document.body.setAttribute("data-sidebar-size", "condensed");
				else document.body.removeAttribute("data-sidebar-size", "condensed");

				if (window.screen.width >= 992) {
					this.$router.afterEach((routeTo, routeFrom) => {
						document.body.classList.remove("sidebar-enable");
					});
				} else {
					document.body.setAttribute("data-sidebar-size", "default");
					document.body.classList.toggle("sidebar-enable");
					this.$router.afterEach((routeTo, routeFrom) => {
						document.body.classList.remove("sidebar-enable");
					});
				}
			},
		},
	};
</script>

<style>
	.v-idle {
		display: none;
	}
	.red-t-head {
		background-color: #d60007;
		color: white;
	}
</style>

<template>
	<!-- Begin page -->
	<div id="wrapper">
		<Sidebar
			v-if="isAdmin"
			:type="leftSidebarType"
			:width="layoutWidth"
			:size="leftSidebarSize"
			:menu="menuPosition"
			:topbar="topbar"
		/>
		<!-- ============================================================== -->
		<!-- Start Page Content here -->
		<!-- ============================================================== -->

		<div class="content-page">
			<div class="content">
				<!-- Check if the user is not active -->
				<!-- Log the user out if idle for the specified idle time in .env file -->
				<v-idle
					@idle="onIdle"
					:duration="idleTime"
					@remind="onremind"
					:events="['mousemove', 'keypress', 'scroll', 'load']"
					:reminders="[120, 60]"
				/>
				<Topbar />
				<!-- Start Content-->
				<div class="container-fluid">
					<slot />
				</div>
			</div>
			<Footer />
		</div>
		<Rightbar />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import AccountModal from "@/components/modals/AccountModal";
	/**
	 * Topbar component
	 */
	export default {
		components: {
			AccountModal,
		},

		data() {
			return {
				languages: [
					{
						flag: require("@/assets/images/flags/us.jpg"),
						language: "en",
						title: "English",
					},
					{
						flag: require("@/assets/images/flags/french.jpg"),
						language: "fr",
						title: "French",
					},
					{
						flag: require("@/assets/images/flags/spain.jpg"),
						language: "es",
						title: "Spanish",
					},
					{
						flag: require("@/assets/images/flags/china.png"),
						language: "zh",
						title: "Chinese",
					},
					{
						flag: require("@/assets/images/flags/arabic.png"),
						language: "ar",
						title: "Arabic",
					},
				],
				current_language: this.$i18n.locale,
				text: null,
				flag: null,
				value: null,
			};
		},

    computed: {
      ...mapGetters("authModule", ["isAdmin"]),
    },

		mounted() {
      if(!this.isAdmin) {
        this.$parent.hideLeftSidebar();
        this.toggleMenu();
      }
			this.value = this.languages.find((x) => x.language === this.$i18n.locale);
			this.text = this.value.title;
			this.flag = this.value.flag;
		},
		methods: {
			...mapActions("authModule", ["logout"]),

			openAccountModal() {
				this.$refs["accountModal"].openModal();
			},

			async logoutUser() {
				const { status, data } = await this.logout();
				if (status == 204) {
					this.$router.push({ path: "/login" });
				}
			},

			/**
			 * Toggle menu
			 */
			toggleMenu() {
				this.$parent.toggleMenu();
			},
			/**
			 * Full screen
			 */
			initFullScreen() {
				document.body.classList.toggle("fullscreen-enable");
				if (
					!document.fullscreenElement &&
					/* alternative standard method */
					!document.mozFullScreenElement &&
					!document.webkitFullscreenElement
				) {
					// current working methods
					if (document.documentElement.requestFullscreen) {
						document.documentElement.requestFullscreen();
					} else if (document.documentElement.mozRequestFullScreen) {
						document.documentElement.mozRequestFullScreen();
					} else if (document.documentElement.webkitRequestFullscreen) {
						document.documentElement.webkitRequestFullscreen(
							Element.ALLOW_KEYBOARD_INPUT
						);
					}
				} else {
					if (document.cancelFullScreen) {
						document.cancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					}
				}
			},
			/**
			 * Toggle rightbar
			 */
			toggleRightSidebar() {
				this.$parent.toggleRightSidebar();
			},
			/**
			 * Horizontal-toggle menu
			 */
			horizonalmenu() {
				let element = document.getElementById("topnav-menu-content");
				element.classList.toggle("show");
			},
		},
	};
</script>
<style scoped>
 .logo-box {
     background: transparent;
     margin-left: -42px;
 }
</style>
<template>
	<!-- Topbar Start -->
	<div class="navbar-custom">
		<div class="container-fluid">
      
			<ul class="list-unstyled topnav-menu float-right mb-0">
				<b-nav-item-dropdown
					right
					class="notification-list topbar-dropdown"
					menu-class="profile-dropdown"
					toggle-class="p-0"
				>
					<template slot="button-content" class="nav-link dropdown-toggle">
						<div class="nav-user mr-0">
							<span class="pro-user-name ml-1 d-block">
								{{
									$store.state.authModule.currentUser
										? $store.state.authModule.currentUser.name
										: ""
								}}
								<i class="mdi mdi-chevron-down"></i>
							</span>
						</div>
					</template>

					<b-dropdown-header>
						<h6 class="text-overflow m-0 py-2">
							{{ $t("navbar.dropdown.name.list.greet") }}
						</h6>
					</b-dropdown-header>

					<b-dropdown-item @click="openAccountModal">
						<i class="remixicon-account-circle-line"></i>
						<span>{{ $t("navbar.dropdown.name.list.account") }}</span>
					</b-dropdown-item>

					<b-dropdown-divider></b-dropdown-divider>
					<a class="dropdown-item" @click.prevent="logoutUser">
						<i class="fe-log-out mr-1"></i>
						<span>{{ $t("navbar.dropdown.name.list.logout") }}</span>
					</a>
				</b-nav-item-dropdown>
				<!----- Theme Settings Button --->
			</ul>

			<ul class="list-unstyled topnav-menu topnav-menu-left m-0" v-if="isAdmin">
				<li>
					<button
						class="button-menu-mobile waves-effect waves-light"
						@click="toggleMenu"
					>
						<i class="fe-menu"></i>
					</button>
				</li>

				<li>
					<!-- Mobile menu toggle (Horizontal Layout)-->
					<a
						class="navbar-toggle nav-link"
						data-toggle="collapse"
						@click="horizonalmenu()"
						data-target="#topnav-menu-content"
					>
						<div class="lines">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</a>
					<!-- End mobile menu toggle-->
				</li>
			</ul>
      <div class="logo-box d-none d-sm-none d-md-block" v-else>
				<router-link to="/" class="logo logo-dark text-center">
					<span class="logo-sm">
						<img src="@/assets/images/logo-sm-dark.png" alt height="24" />
					</span>
					<span class="logo-lg">
						<img src="@/assets/images/logo-dark.png" alt height="20" />
					</span>
				</router-link>

				<router-link to="/" class="logo logo-light text-center">
					<span class="logo-sm">
						<img
							src="@/assets/images/Original-Techno-G-Logo-(white-&-red-2).png"
							alt
							height="54"
						/>
					</span>
					<span class="logo-lg">
						<img
							src="@/assets/images/Original-Techno-G-Logo-(white-&-red-2).png"
							alt
							height="50"
						/>
					</span>
				</router-link>
			</div>
			<div class="clearfix"></div>

			<AccountModal ref="accountModal" />
		</div>
	</div>
	<!-- end Topbar -->
</template>

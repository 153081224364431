<template>
	<div class="button-list">
		<b-modal
			centered
			title="Account Settings"
			ref="modal"
			hide-footer
			title-class="font-18"
			body-class="p-4"
			@hide="handleModalClose"
		>
			<form class="px-3" action="#">
				<div class="form-group">
					<label for="username">Name</label>
					<input
						disabled
						class="form-control"
						type="email"
						id="username"
						required=""
						:value="(currentUser && currentUser.name) || ''"
					/>
				</div>

				<div class="form-group">
					<label for="emailaddress">Email address</label>
					<input
						disabled
						static
						class="form-control"
						type="email"
						id="emailaddress"
						required=""
						:value="(currentUser && currentUser.email) || ''"
					/>
				</div>

				<template v-if="showChangePassowrd">
					<form action="#" @submit.prevent="savePassword">
						<div class="form-group">
							<label for="newPassword">New Password</label>
							<input
								class="form-control"
								type="password"
								id="newPassword"
								required=""
								v-model="$v.newPassword.$model"
								:class="{ 'is-invalid': submitted && $v.newPassword.$error }"
							/>
							<div
								v-if="submitted && $v.newPassword.$error"
								class="invalid-feedback"
							>
								<span v-if="!$v.newPassword.required"
									>This value is required.</span
								>
								<span v-if="!$v.newPassword.minLength"
									>Password must be at least 8 characters.</span
								>
							</div>
						</div>
						<div class="form-group">
							<label for="confirmPassword">Confirm Password</label>
							<input
								class="form-control"
								type="password"
								id="confirmPassword"
								required=""
								v-model="$v.confirmPassword.$model"
								:class="{
									'is-invalid': submitted && $v.confirmPassword.$error,
								}"
							/>
							<div
								v-if="submitted && $v.confirmPassword.$error"
								class="invalid-feedback"
							>
								<span v-if="!$v.confirmPassword.required"
									>This value is required.</span
								>
								<span v-else-if="!$v.confirmPassword.sameAsNewPassword"
									>This value should be the same as the new password.</span
								>
							</div>
						</div>

						<div class="form-group">
							<div class="row text-left">
								<div class="col-12">
									<b-button type="submit" variant="outline-success"
										>Save Password</b-button
									>
								</div>
							</div>
						</div>
					</form>
				</template>

				<template v-if="!showChangePassowrd">
					<div class="form-group">
						<label for="password">Password</label>
						<div>
							<b-button
								variant="outline-primary"
								@click="showChangePassowrd = true"
								>CHANGE MY PASSWORD</b-button
							>
						</div>
					</div>
				</template>
			</form> </b-modal
		><!-- /.modal -->
	</div>
</template>

<script>
	import { mapActions, mapState } from "vuex";
	import { required, minLength, sameAs } from "vuelidate/lib/validators";
	export default {
		data() {
			return {
				submitted: false,
				showChangePassowrd: false,
				confirmPassword: "",
				newPassword: "",
			};
		},

		computed: {
			...mapState("authModule", ["currentUser"]),
		},

		methods: {
			...mapActions("userModule", ["ChangeUserPassword"]),

			openModal() {
				this.$refs["modal"].show();
			},

			handleModalClose() {
				this.showChangePassowrd = false;
				this.submitted = false;
				this.newPassword = "";
				this.confirmPassword = "";
			},

			async savePassword() {
				this.submitted = true;
				this.$v.$touch();

				if (!this.$v.$invalid) {
					const result = await this.ChangeUserPassword({
						newPassword: this.newPassword,
						userId: this.currentUser._id,
					});
					if (result.status == 204) {
						this.$bvToast.toast(`Password was successfully updated`, {
							title: `Success!`,
							variant: "success",
							solid: true,
						});
						this.$refs["modal"].hide();
					} else {
						this.$bvToast.toast(`Unable to update because ${result.message}`, {
							title: `Error!`,
							variant: "danger",
							solid: true,
						});
					}
				}
			},
		},

		validations: {
			newPassword: {
				required,
				minLength: minLength(8),
			},

			confirmPassword: {
				required,
				sameAsPassword: sameAs("newPassword"),
			},
		},
	};
</script>

<style scoped></style>

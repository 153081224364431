<script>
	/**
	 * Footer Component
	 */
	export default {};
</script>

<template>
	<!-- Footer Start -->
	<footer class="footer">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-6">
					<img
						src="@/assets/images/wonder-version-2-logo.png"
						alt
						height="30"
					/>
					Wnder by Black Sigma Technologies
				</div>
			</div>
		</div>
	</footer>
	<!-- end Footer -->
</template>
